<script>
export default {
  props: {
    tabelas: {
      type: Object,
    },
  },
  computed: {
    getClasse() {
      switch (this.produto.produto_m_s) {
        case "M":
          return "Mercadoria";
        case "S":
          return "Serviço";

        default:
          return "Produto";
      }
    },
  },
};
</script>

<template>
  <div class="container">
    <!-- d-flex justify-content-center align-items-center -->
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "CPF/CNPJ:" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ tabelas.cpfcnpj }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Placa:" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-info">
              <strong>{{ tabelas.placa }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Capacidade Max:" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-danger">
              <strong>{{ tabelas.capacidade_max }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Tipo de Pessoa:" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-success">
              <strong>{{
                tabelas.tp_pessoa == 1 ? "Fisica" : "Juridica"
              }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Carroceria:" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-success">
              <strong>{{
                tabelas.carroceria_id == 1 ? "Fisica" : "Juridica"
              }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Tara:" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-success">
              <strong>{{ tabelas.tara == 1 ? "Fisica" : "Juridica" }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Kilometragem Atual" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-danger">
              <strong>{{ tabelas.km_atual }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Cidade" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-info">
              <strong>{{ tabelas.cidadade_id }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "RNTRC" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-info">
              <strong>{{ tabelas.rntrc }}</strong>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ "Data de Criação" }}</strong>
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p class="badge badge-secondary">
              <strong>{{ tabelas.created_at }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <p>
          CNPJ: <strong>{{ tabelas.cpfcnpj }}</strong>
        </p>
        <p>
          Placa: <strong>{{ tabelas.placa }}</strong>
        </p>
        <p>
          Capacidade Maxima: <strong>{{ tabelas.capacidade_max }}</strong>
        </p>
        <p>
          Tipo de Pessoa: <strong>{{ tabelas.tp_pessoa }}</strong>
        </p>
        <p>
          Carroceria: <strong>{{ tabelas.carroceria_id }}</strong>
        </p>
        <p>
          Tara: <strong>{{ tabelas.tara }}</strong>
        </p>
        <p>
          km_atual: <strong>{{ tabelas.km_atual }}</strong>
        </p>
        <p>
          Cidade: <strong>{{ tabelas.cidadade_id }}</strong>
        </p>
        <p>
          Rntrc: <strong>{{ tabelas.rntrc }}</strong>
        </p>
        <p>
          Data Criação: <strong>{{ tabelas.created_at }}</strong>
        </p> -->
  </div>
</template>